import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Card, CardMedia, Grid, CircularProgress } from '@mui/material';
import { motion } from 'framer-motion';
import DOMPurify from 'dompurify';
import PrevBar from './PrevBar';
import Footer from './Footer';

const ArtworkPage = () => {
  const { slug } = useParams();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/data/data.json')
      .then((response) => response.json())
      .then((data) => {
        const selectedItem = data.find((item) => item.slug === slug);
        setItem(selectedItem);
        setLoading(false);
      });
  }, [slug]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!item) {
    return <div>Error loading item details</div>;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <PrevBar />
      <Box sx={{ p: { xs: 2, sm: 3 }, pt: { xs: '56px', sm: '64px' } }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: { xs: '10vh', sm: '0' } }}>
              {/* Preview Image */}
              <Card sx={{ boxShadow: 'none' }}>
                <CardMedia
                  component="img"
                  image={`/assets/images/${item.Preview}`}
                  alt={`Image of ${item.Name}`}
                  sx={{ width: '100%' }}
                />
              </Card>

              {/* Affichage des images supplémentaires */}
              {item.images && item.images.length > 0 && (
                item.images.map((image, index) => (
                  <Card key={index} sx={{ boxShadow: 'none' }}>
                    <CardMedia
                      component="img"
                      image={`/assets/images/${image}`}
                      alt={`Image ${index + 1} of ${item.Name}`}
                      sx={{ width: '100%' }}
                    />
                  </Card>
                ))
              )}
            </Box>
          </Grid>

          {/* Version mobile : affichage des infos en bas de page */}
          {window.innerWidth <= 600 ? (
            <Grid
              sx={{
                position: 'fixed',
                bottom: '0',
                background: 'white',
                height: 'fit-content',
                width: '100vw',
                borderTop: '1px solid #ddd',
                pt: 2,
                pb: 4,
                pl: 2,
                pr: 2,
                zIndex: 10,
              }}
            >
              <Box>
                <Typography variant="h4" sx={{ mb: 0 }}>{item.Name}</Typography>
                <Typography variant="body1" sx={{ mt: 1 }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.description) }}></Typography>
              </Box>
            </Grid>
          ) : (
            <Grid item md={5} sx={{ position: 'sticky', top: '10vh', height: 'fit-content' }}>
              <Box sx={{ px: 2, pt: '22vh' }}>
                <Typography variant="h3" sx={{ mb: 0 }}>{item.Name}</Typography>
                <Typography variant="body1" sx={{ mt: 1 }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.description) }}></Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Footer />
    </motion.div>
  );
};

export default ArtworkPage;
