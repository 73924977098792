import React, { useState } from 'react';
import { Box, IconButton, Typography, Link } from '@mui/material';
import InstagramIcon from '../img/instagram.svg';
import VimeoIcon from '../img/vimeo.svg';
import MailIcon from '../img/mail.svg';

function Footer() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 20px 20px',
      mt: 'auto',
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: '5px',
      }}>
        <IconButton aria-label="instagram" component="a" href="https://www.instagram.com" target="_blank" color="inherit">
          <img height="30" src={InstagramIcon} alt="Instagram" />
        </IconButton>
        <IconButton aria-label="vimeo" sx={{ mr: 1, ml: 1 }} component="a" href="https://www.vimeo.com" target="_blank" color="inherit">
          <img height="35" src={VimeoIcon} alt="Vimeo" />
        </IconButton>
        {/* Changer le bouton pour un lien mailto */}
        <IconButton aria-label="mail" component="a" href="mailto:hey@kajikaaki.com" color="inherit">
          <img height="20" src={MailIcon} alt="Mail" />
        </IconButton>
      </Box>
      <Typography sx={{ fontFamily: 'Manrope', fontWeight: 'bold' }} variant="body2" align="center">
        © 2024 Kajika Aki
      </Typography>
    </Box>
  );
}

export default Footer;
