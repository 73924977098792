import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { AppBar, Toolbar, IconButton, Box, useTheme, useMediaQuery, Slide, Button, Dialog } from '@mui/material';
import ContactForm from './ContactForm'; 
import logo from '../img/logo.svg'; 
import menuIconSvg from '../img/menu.svg'; 

export default function LogoBar() {
  const [showMenu, setShowMenu] = useState(false);
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleContactDialog = (open) => () => {
    setIsContactDialogOpen(open);
  };

  const handleAllClick = () => {
    navigate('/');
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <AppBar position="static" elevation={0} color="background">
        <Toolbar sx={{ justifyContent: 'space-between', position: 'relative' }}>
          {/* Menu icon on the left */}
          <IconButton aria-label="menu" onClick={toggleMenu}>
            <img src={menuIconSvg} alt="Menu" style={{ height: '30px' }} />
          </IconButton>

          {/* Logo centered absolutely */}
          <Box sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            cursor: 'pointer',
          }} onClick={handleAllClick}>
            <img src={logo} alt="Logo" style={{ height: '25px' }} />
          </Box>

          {/* Empty space to balance the toolbar layout */}
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>

        {/* Sliding menu */}
        <Slide direction="down" in={showMenu} mountOnEnter unmountOnExit>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            bgcolor: 'background.paper',
            position: 'absolute',
            top: 0,
            left: '20%',
            width: '60%',
            height: { xs: '56px', sm: '64px' },
          }}>
            <Button color="inherit" sx={{ fontFamily: 'Manrope', fontWeight: 'bold', textTransform: 'uppercase' }} onClick={() => navigate('/flowers')}>Flowers</Button>
            <Button color="inherit" sx={{ fontFamily: 'Manrope', fontWeight: 'bold', textTransform: 'uppercase' }} onClick={() => navigate('/monsters')}>Monsters</Button>
            <Button color="inherit" sx={{ fontFamily: 'Manrope', fontWeight: 'bold', textTransform: 'uppercase' }} onClick={() => navigate('/about')}>About</Button>
          </Box>
        </Slide>
      </AppBar>

      {/* Contact form dialog */}
      <Dialog
        open={isContactDialogOpen}
        onClose={toggleContactDialog(false)}
        fullWidth={true}
        maxWidth={false}
        PaperProps={{
          sx: {
            width: isMobile ? '90vw' : '35vw',
            paddingY: isMobile ? 1 : 3,
            paddingX: isMobile ? 1 : 3,
          },
        }}
      >
        <ContactForm onSubmitSuccess={toggleContactDialog(false)} />
      </Dialog>
    </>
  );
}
