import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Card, CardMedia, CardActionArea, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from './Footer';
import LogoBar from './LogoBar'; // Importez le composant LogoBar

const FlowersPage = () => {
  const navigate = useNavigate();
  const [flowers, setFlowers] = useState([]);

  useEffect(() => {
    fetch('/data/data.json')
      .then((response) => response.json())
      .then((data) => {
        const flowersArray = data.filter((item) => item.category === 'flowers');
        setFlowers(flowersArray);
      });
  }, []);

  if (flowers.length === 0) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{ minHeight: '100vh' }}
    >
      <Helmet>
        <title>KAJIKA - Flowers</title>
        <meta name="description" content="Explore our beautiful collection of Flower." />
      </Helmet>
      <Box>
        <LogoBar />
        <Box 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center" 
          sx={{ px: { xs: 2, md: 4 } }}
        >
          {flowers.map((item) => (
            <motion.div
              key={item.slug}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{ width: '100%' }}
            >
              <Card 
                onClick={() => navigate(`/artwork/${item.slug}`)} 
                sx={{ borderRadius: 2, boxShadow: 'none', width: '100%', marginBottom: 4 }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    sx={{ height: '90vh', width: '100%', objectFit: 'cover', borderRadius: 2 }}
                    image={`/assets/images/${item.Preview}`}
                    alt={item.Name}
                  />
                </CardActionArea>
              </Card>
            </motion.div>
          ))}
        </Box>
        <Footer />
      </Box>
    </motion.div>
  );
};

export default FlowersPage;
