import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid, Card, CardMedia, CardActionArea, CircularProgress } from '@mui/material';
import NavBar from './NavBar';
import ImageFilter from './ImageFilter';
import logo from '../img/logo.svg';
import logoKanji from '../img/logo-kanji.svg';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const HomePage = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState('all');
  const [shuffledItems, setShuffledItems] = useState([]);
  const [hoverLogo, setHoverLogo] = useState(logo);

  useEffect(() => {
    fetch('/data/data.json')
      .then((response) => response.json())
      .then((data) => {
        const shuffledArray = shuffleArray(data);
        setShuffledItems(shuffledArray);
      });
  }, []);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    const section = document.getElementById('artwork-section');
    if (section) {
      window.scrollTo({ top: section.offsetTop, behavior: 'smooth' });
    }
  };

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const filteredItems = React.useMemo(() => {
    return shuffledItems.filter(item =>
      !item.category.includes('flowers') && 
      (filter === 'all' || item.category.includes(filter))
    );
  }, [shuffledItems, filter]);

  if (shuffledItems.length === 0) return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <CircularProgress />
    </Box>
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Helmet>
        <title>KAJIKA 安芸</title>
        <meta name="description" content="Dive into the heart of my two giant frescoes." />
      </Helmet>
      <Box>
        <NavBar />
        <Box sx={{
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          paddingTop: '45vh', 
          paddingBottom: '45vh',
          paddingRight: 4,
          paddingLeft: 4
        }}>
          <motion.img
            src={hoverLogo}
            alt="Logo"
            style={{ maxWidth: '100%', height: '20vh' }}
            onHoverStart={() => setHoverLogo(logoKanji)}
            onHoverEnd={() => setHoverLogo(logo)}
            onTapStart={() => setHoverLogo(logoKanji)}
            onTap={() => setHoverLogo(logo)}
            animate={{ opacity: [0.5, 1] }}
            transition={{ duration: 0.5 }}
          />
        </Box>
        <Grid container spacing={2} sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 0, sm: 1 } }} id="artwork-section" key={filter}>
        {filteredItems.map((item) => (
          <Grid item xs={12} sm={4} key={item.slug}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Card elevation={0} onClick={() => navigate(`/artwork/${item.slug}`)}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    sx={{
                      height: 1, 
                      width: 1,
                      objectFit: 'cover'
                    }}
                    image={`/assets/images/${item.Preview}`}
                    alt={item.Name}
                  />
                </CardActionArea>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
        <ImageFilter currentFilter={filter} onFilterChange={handleFilterChange} />
        <Footer />
      </Box>
    </motion.div>
  );
};

export default HomePage;
