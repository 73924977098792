import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import allActiveIcon from '../img/apple00.svg';
import allInactiveIcon from '../img/apple01.svg';
import realistIcon from '../img/realist.svg';
import foxIcon from '../img/fox.svg';
import flowersIcon from '../img/flowers.svg';

function ImageFilter({ currentFilter, onFilterChange, alwaysVisible }) {
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate(); // Already used in the file

  useEffect(() => {
    const footerHeight = 60; // Hauteur du footer réglable
    
    const handleScroll = () => {
      const footer = document.getElementById('footer');
      let footerTop = document.body.offsetHeight;
      if (footer) {
        footerTop = footer.offsetTop;
      }
      
      const scrollPosition = window.scrollY + window.innerHeight;
      let show = window.scrollY > window.innerHeight * 0.4 && scrollPosition < (footerTop - footerHeight);

      if (alwaysVisible && scrollPosition < (footerTop - footerHeight)) {
        show = true;
      }

      setShowFilter(show);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [alwaysVisible]);

  return (
    <Box sx={{
      position: 'fixed',
      bottom: showFilter ? '20px' : '-100px',
      left: `calc(50% - 110px)`,
      transition: 'bottom 0.3s ease',
      display: 'flex',
      gap: 1,
      backgroundColor: 'background.paper',
      borderRadius: '50px',
      padding: '10px 20px',
      border: '1px solid #eee',
      zIndex: 1
    }}>
      <IconButton onClick={() => onFilterChange('all')} color="inherit">
        <img height={30} src={currentFilter === 'all' ? allActiveIcon : allInactiveIcon} alt="All" />
      </IconButton>
      <IconButton onClick={() => onFilterChange('realist')} color="inherit">
        <img height={30} src={realistIcon} alt="Realist" />
      </IconButton>
      <IconButton onClick={() => onFilterChange('fox')} color="inherit">
        <img height={30} src={foxIcon} alt="Fox" />
      </IconButton>
      {/* Modification ici pour naviguer vers /flowers */}
      <IconButton onClick={() => navigate('/flowers')} color="inherit">
        <img height={30} src={flowersIcon} alt="Flowers" />
      </IconButton>
    </Box>
  );
}

export default ImageFilter;
