import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box, useTheme, useMediaQuery, Dialog } from '@mui/material';
import ContactForm from './ContactForm';
import logo from '../img/logo.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function MonstersBar() {
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleContactDialog = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsContactDialogOpen(open);
  };

  const goHomePage = () => {
    navigate('/');
  };

  const goToMonsters = () => {
    navigate('/monsters');
  };

  return (
    <>
      <AppBar position="fixed" elevation={0} color="background">
        <Toolbar sx={{ justifyContent: 'space-between', position: 'relative' }}>
          {/* Back icon on the left */}
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start', alignItems:'center' }}>
            <IconButton color="inherit" aria-label="back" onClick={goToMonsters} size="small">
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
            <Box component="span"
                 sx={{ fontFamily: 'Manrope', fontWeight: 'bold', textTransform: 'uppercase', fontSize: '0.75rem', cursor: 'pointer' }}
                 onClick={goToMonsters}>
                All
            </Box>
          </Box>
          
          {/* Logo centered absolutely */}
          <Box sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            cursor: 'pointer',
          }} onClick={goHomePage}>
            <img src={logo} alt="Logo" style={{ height: '25px' }} />
          </Box>

          {/* Empty space to balance the layout */}
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>

      {/* Contact form dialog */}
      <Dialog
        open={isContactDialogOpen}
        onClose={toggleContactDialog(false)}
        fullWidth={true}
        maxWidth={false}
        PaperProps={{
          sx: {
            width: isMobile ? '90vw' : '35vw',
            paddingY: isMobile ? 1 : 3,
            paddingX: isMobile ? 1 : 3,
          },
        }}
      >
        <ContactForm onSubmitSuccess={toggleContactDialog(false)} />
      </Dialog>
    </>
  );
}
