import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AnimatePresence } from 'framer-motion';

import theme from './theme';
import './App.css';

import HomePage from './components/HomePage';
import ArtworkPage from './components/ArtworkPage';
import GalleryPage from './components/GalleryPage';
import AboutPage from './components/AboutPage';
import NotFoundPage from './components/NotFoundPage'; 
import MonstersPage from './components/Monsters/MonstersPage'; 
import ImieiamoriPage from './components/Monsters/ImieiamoriPage'; 
import LittlemermaidPage from './components/Monsters/LittlemermaidPage'; 
import FlowersPage from './components/FlowersPage'; 


function AppWithAnimatePresence() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomePage />} />

        <Route path="*" element={<NotFoundPage />} />

        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/artwork/:slug" element={<ArtworkPage />} />

        <Route path="/about" element={<AboutPage />} />

        <Route path="/flowers" element={<FlowersPage />} />

        <Route path="/monsters" element={<MonstersPage />} />
        <Route path="/monsters/imieiamori" element={<ImieiamoriPage />} />
        <Route path="/monsters/littlemermaid" element={<LittlemermaidPage />} />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  return (
      <ThemeProvider theme={theme}>
            <Router>
                <AppWithAnimatePresence />
            </Router>
      </ThemeProvider>
  );
}

export default App;
